import { useState } from 'react';
import MagicLinkStatus from './MagicLink.types';
import { TextIconColor, Typography, TypographySize } from '~/components/core';
type MagicLinkProps = {
  magicLinkError: string | undefined;
};
const MagicLink = ({
  magicLinkError
}: MagicLinkProps) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(MagicLinkStatus.Active);
  const handleMagicLink = () => {
    if (status === MagicLinkStatus.Pending || email === '') return;
    setStatus(MagicLinkStatus.Pending);
    fetch('/api/magic-link?version=v2', {
      method: 'POST',
      body: email
    }).then(response => response.json()).then(() => {
      setStatus(MagicLinkStatus.EmailSent);
      setEmail('');
    }).catch(e => {
      setStatus(MagicLinkStatus.EmailSentError);
      console.error(e);
    });
  };
  return <div css={{
    "height": "108px"
  }}>
      <div css={{
      "position": "relative",
      "marginTop": "1.5rem",
      "width": "100%"
    }}>
        <input css={{
        "display": "block",
        "width": "100%",
        "borderRadius": "9999px",
        "borderWidth": "1px",
        "borderColor": "#095A7C37",
        "paddingLeft": "1rem",
        "paddingRight": "1rem",
        "paddingTop": "0.5rem",
        "paddingBottom": "0.5rem",
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem",
        "fontWeight": "468",
        "--tw-text-opacity": "1",
        "color": "rgb(22 25 37 / var(--tw-text-opacity))",
        "&:focus": {
          "--tw-border-opacity": "1",
          "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))",
          "outline": "2px solid transparent",
          "outlineOffset": "2px"
        }
      }} disabled={status === MagicLinkStatus.Pending} id="search-dropdown" onChange={event => {
        if (status === MagicLinkStatus.Pending) return;
        setStatus(MagicLinkStatus.Active);
        setEmail(event.target.value);
      }} onKeyDown={event => {
        if (event.key === 'Enter') {
          handleMagicLink();
        }
      }} placeholder="Email Address" type="text" value={email} />
        <div className={`text-highlight absolute right-0 top-0 h-full rounded-r-full border px-5 py-2 text-sm font-semibold ${status === MagicLinkStatus.Pending ? 'disabled cursor-not-allowed bg-gray-400' : 'bg-blue-navy cursor-pointer'}`} onClick={() => {
        handleMagicLink();
      }}>
          Send Magic Link
        </div>
      </div>

      {status === MagicLinkStatus.EmailSent && <Typography color={TextIconColor.PRIMARY} size={TypographySize.X_SMALL}>
          Link sent, check your email for the magic link to sign in.
        </Typography>}
      {status === MagicLinkStatus.EmailSentError && <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.X_SMALL}>
          An error occurred. Please try again later.
        </Typography>}
      {magicLinkError && <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.X_SMALL}>
          {magicLinkError}
        </Typography>}
    </div>;
};
export default MagicLink;