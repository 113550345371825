import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import { TextIconColor, Typography, TypographySize } from '~/components/core';
const AirGappedPassword = () => {
  const [password, setPassword] = useState('');
  const [busy, setBusy] = useState(false);
  const [loginError, setLoginError] = useState<string>();
  const router = useRouter();
  const handleAirGappedPassword = async () => {
    if (busy) return;
    setBusy(true);
    setLoginError('');
    const result = await signIn('air-gapped', {
      password,
      redirect: false
    });
    if (result?.error) {
      setLoginError('Invalid Password');
    } else {
      void router.push('/');
    }
    setBusy(false);
  };
  return <div css={{
    "height": "108px"
  }}>
      <div css={{
      "position": "relative",
      "marginTop": "1.5rem",
      "width": "100%"
    }}>
        <input css={{
        "display": "block",
        "width": "100%",
        "borderRadius": "9999px",
        "borderWidth": "1px",
        "borderColor": "#095A7C37",
        "paddingLeft": "1rem",
        "paddingRight": "1rem",
        "paddingTop": "0.5rem",
        "paddingBottom": "0.5rem",
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem",
        "fontWeight": "468",
        "--tw-text-opacity": "1",
        "color": "rgb(22 25 37 / var(--tw-text-opacity))",
        "&:focus": {
          "--tw-border-opacity": "1",
          "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))",
          "outline": "2px solid transparent",
          "outlineOffset": "2px"
        }
      }} disabled={busy} id="search-dropdown" onChange={event => {
        if (busy) return;
        setPassword(event.target.value);
      }} onKeyDown={event => {
        if (event.key === 'Enter') {
          void handleAirGappedPassword();
        }
      }} placeholder="Password" type="password" value={password} />
        <button className={`text-highlight absolute right-0 top-0 h-full rounded-r-full border px-5 py-2 text-sm font-semibold ${busy ? 'disabled cursor-not-allowed bg-gray-400' : 'bg-blue-navy cursor-pointer'}`} onClick={() => {
        void handleAirGappedPassword();
      }} type="button">
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL}>
            Log In
          </Typography>
        </button>
      </div>

      {loginError && <div className="w-ful" css={{
      "position": "relative",
      "marginTop": "0.25rem",
      "textAlign": "center"
    }}>
          <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.SMALL}>
            {loginError}
          </Typography>
        </div>}
    </div>;
};
export default AirGappedPassword;