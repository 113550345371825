import _styled from "styled-components";
import { signIn } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { LoginError } from '../../LoginScreen.types';
import { Typography, TextIconColor, TypographySize, Button, ButtonType } from '~/components/core';
import { ROOT_ROUTE } from '~/constants';
const EmailInputContainer = _styled.div({
  "height": "108px"
});
const EmailInputContent = _styled.div({
  "position": "relative",
  "marginTop": "1.5rem",
  "width": "100%"
});
const EmailInput = _styled.input({
  "display": "block",
  "width": "100%",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "borderColor": "#095A7C37",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "468",
  "--tw-text-opacity": "1",
  "color": "rgb(22 25 37 / var(--tw-text-opacity))",
  "&:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  }
});
const LoginButton = _styled.button({
  "position": "absolute",
  "right": "0px",
  "top": "0px",
  "height": "100%",
  "borderTopRightRadius": "9999px",
  "borderBottomRightRadius": "9999px",
  "borderWidth": "1px",
  "paddingLeft": "1.25rem",
  "paddingRight": "1.25rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "600",
  "--tw-text-opacity": "1",
  "color": "rgb(84 254 209 / var(--tw-text-opacity))"
});
const ButtonContainer = _styled.div({
  "display": "flex",
  "justifyContent": "center"
});
const ErrorContainer = _styled.div({
  "display": "flex",
  "justifyContent": "center"
});
const Sso = ({
  returnUrl,
  setIsUsingSso,
  error
}: {
  error: string | undefined;
  returnUrl: string | undefined;
  setIsUsingSso: (isUsingSso: boolean) => void;
}) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (error === LoginError.SSOError) {
      setErrorMessage('Your organization requires SSO to sign in.');
    }
  }, [error]);
  const handleSsoSignIn = async () => {
    const domain = email.split('@')[1];
    if (!domain) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    await fetch('/api/sso', {
      method: 'POST',
      body: domain
    }).then(res => res.json()).then(async response => {
      if (response.workosOrgId) {
        await signIn('workos', {
          callbackUrl: returnUrl ?? ROOT_ROUTE
        }, {
          organization: response.workosOrgId
        });
      } else {
        setErrorMessage('SSO connection was not found for this domain.');
      }
    }).catch(e => {
      console.error(e);
      setErrorMessage('Something went wrong, please try again.');
    });
  };
  return <EmailInputContainer>
      <EmailInputContent>
        <EmailInput onChange={e => setEmail(e.target.value)} onKeyDown={e => {
        if (e.key === 'Enter') {
          void handleSsoSignIn();
        }
      }} placeholder="Email" value={email} />
        <LoginButton onClick={handleSsoSignIn} type="button">
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL}>
            Log In
          </Typography>
        </LoginButton>
      </EmailInputContent>
      {!!errorMessage && <ErrorContainer>
          <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.SMALL}>
            {errorMessage}
          </Typography>
        </ErrorContainer>}
      <ButtonContainer>
        <Button label="Back" onClick={() => setIsUsingSso(false)} type={ButtonType.TEXT} />
      </ButtonContainer>
    </EmailInputContainer>;
};
export default Sso;